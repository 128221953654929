const URL = process.env.REACT_APP_DB_URL
const AUTH = process.env.REACT_APP_SEQ_AUTH

class CRUD {

    get = async (path) => {
        const response = await fetch(URL + path)
        if (response.ok) {
            return await response.json();
        } else {
            throw response
        }
    }

    post = async (path, obj) => {
        const response = await fetch(URL + path, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": AUTH
            },
            body: JSON.stringify(obj),
        })
        if (response.ok) {
            return await response.json();
        } else {
            throw response
        }
    }

    put = async (path, obj) => {
        const response = await fetch(URL + path, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": AUTH
            },
            body: JSON.stringify(obj),
        })
        if (response.ok) {
            return await response.json();
        } else {
            throw response
        }
    }

    delete = async (path, id) => {
        const response = await fetch(URL + path + id, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": AUTH
            }
        })
        if (response.ok) {
            return await response.json();
        } else {
            throw response
        }
    }
}

export default new CRUD()
