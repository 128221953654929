import React from 'react';
import "./SelectButtons.css"

function SelectButtons({
  front,
  rear,
  setFront,
  setRear,
}) {

  const handleButtonClick = (option) => {
    if (option === "front" && rear !== null) {
      if (front && !rear) { // If turning off "front" and "rear" is already off
        setFront(false);
        setRear(true);      // Force "rear" to be on
      }
      setFront(!front);
    } else if (option === "rear" && front !== null) {
      if (rear && !front) { // If turning off "rear" and "front" is already off
        setRear(false);
        setFront(true);     // Force "front" to be on
        return
      }
      setRear(!rear);
    }
  };

  return (
    <div className="toggle-buttons">
      {front !== null ?
        <button
          className={front ? 'active' : ''}
          onClick={() => handleButtonClick("front")}
        >
          Front
        </button> : null
      }
      {rear !== null ?
        <button
          className={rear ? 'active' : ''}
          onClick={() => handleButtonClick("rear")}
        >
          Rear
        </button> : null
      }
    </div>
  )
}

export default SelectButtons