import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import './App.css';
import { ThemeProvider } from "./utils/themeContext";
import Layout from './presentation/layout/Layout/Layout';
import Hero from "./presentation/pages/Hero/Hero"
import Products from "./presentation/pages/Products/Products"
import { getAllProducts } from "./services/ProductService";

export default function App() {
  const [isAdmin, setIsAdmin] = useState(false)
  const [products, setProducts] = useState([])
  useEffect(() => {
    async function getData() {
      try {
        const response = await getAllProducts()
        setProducts(response)
      } catch (e) {
        console.error(e)
      }
    }
    getData()
  }, [])

  return (
    <Router>
      <ThemeProvider>
        <Layout isAdmin={isAdmin} setIsAdmin={setIsAdmin}>
          <Routes>
            <Route path="/" element={<Hero />}></Route>
            <Route path="/products" element={<Products products={products} setProducts={setProducts} isAdmin={isAdmin} />}></Route>
            <Route path="/products/:id" element={<Products products={products} isAdmin={isAdmin} />}></Route>
            <Route path="/*" element={<div><img src="https://www.rader-international.com//images/preloader.gif" alt="idk" style={{ maxWidth: '100%' }} /></div>}></Route>
          </Routes>
        </Layout>
      </ThemeProvider>
    </Router>
  )
}
