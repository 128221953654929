import React, { useContext } from "react";
import "./Footer.css";
import { AiFillFacebook, AiFillInstagram, AiFillGithub } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { ThemeContext } from '../../../utils/themeContext'

export default function Footer() {

    const location = useLocation()
    const { isDarkMode } = useContext(ThemeContext);

    return (
        <footer>
            <div className="footerContent">
                <div>
                    <div className="logo_footer_div">
                        <img
                            src={!isDarkMode ? require("../../../assets/images/Overdrivelogo.png") : require("../../../assets/images/OverdrivelogoWhite.png")}
                            alt="logo"
                            className="logo_footer"
                        />
                    </div>
                    <p className="text">
                        ©2022 Overdrive™
                    </p>
                    <div className="social_links">
                        <a href="https://www.facebook.com/Overdrive-Number-Plates-101512529215072">
                            <AiFillFacebook size={"50"} />
                        </a>
                        <a href="https://www.instagram.com/overdriveregistrations/">
                            <AiFillInstagram size={"50"} />
                        </a>
                    </div>
                </div>
                <div className="text">
                    <h3>Site Map</h3>
                    <Link to="/" state={{ previousPath: location.pathname }}>
                        <div>
                            home
                        </div>
                    </Link>
                    <Link to="/products" state={{ previousPath: location.pathname }}>
                        <div>
                            products
                        </div>
                    </Link>
                </div>
                <br />
                <address>
                    <p>Contact us</p>
                    <a href="tel:07967 111110">07967 111110</a>
                    <br />
                    <a href="mailto:bav@overdrivetech.co.uk">
                        bav@overdrivetech.co.uk
                    </a>
                    <br />
                </address>
            </div>
            <hr />
            <div className="text">
                <a href="https://github.com/DanApollo">
                    <AiFillGithub size={"30"} />
                </a>
            </div>
        </footer>
    );
}
