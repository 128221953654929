import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductCard from "../../components/ProductCard/ProductCard";
import ProductModal from "../../components/ProductModal/ProductModal";
import { useParams } from "react-router-dom";
import "./Products.css";

export default function Products({
    products,
    setProducts,
    isAdmin
}) {

    const [product, setProduct] = useState()
    const [editable, setEditable] = useState(0)
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state?.previousPath === "/") {
            window.scrollTo(0, 0);
        }
    }, [location])

    useEffect(() => {
        const fetchProduct = async () => {
            const product = products.find(element => element.id === parseInt(id))
            setProduct(product)
        }
        if (products.length > 0) {
            if (id && (isNaN(id) || !products.find(element => element.id === Number(id)))) {
                navigate('/404')
            } else if (id) {
                fetchProduct()
                const body = document.body;
                body.style.overflow = 'hidden';
                return () => body.style.overflow = 'initial';
            } else {
                setProduct()
            }
        }
    }, [id, products, navigate])

    return (
        <>
            {product ?
                <div className="modal">
                    <ProductModal product={product} setProduct={setProduct} isAdmin={isAdmin} />
                </div> : null
            }
            <div className="cards">
                {products.map((product, index) => 
                <ProductCard
                    product={product}
                    newProduct={() => {}} 
                    editProduct={() => {}} 
                    deleteProduct={() => {}} 
                    isAdmin={isAdmin} 
                    key={index} 
                    editable={editable} 
                    setEditable={setEditable}
                />)}
            </div>
        </>
    );
}