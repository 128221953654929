import crud from "./CRUD";

const PATH = "/products"

export const getAllProducts = async () => await crud.get(PATH)

export const postProduct = async (obj) => await crud.post(PATH, obj)

export const deleteProduct = async (id) => await crud.delete(PATH + "/", id)

export const editProduct = async (obj) => await crud.put(PATH, obj)
