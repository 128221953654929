import React, { useEffect, useState } from "react";
import "./Hero.css";
import ScrollToTop from "../../../utils/ScrollToTop";
import { getNumberPlateTypes } from "../../../services/NumberPlateTypeService";
import ToggleButtons from "../../components/ToggleButtons/ToggleButtons"
import SelectButtons from "../../components/SelectButtons/SelectButtons"
import Checkbox from "../../components/Checkbox/Checkbox"
import { getPlateImages } from "../../../services/PlateImageService";

let timeout = null

export default function Hero() {

    const [plates, setPlates] = useState([])
    const [selectedPlate, setSelectedPlate] = useState(0)

    const [front, setFront] = useState(null)
    const [rear, setRear] = useState(null)
    const [checkbox, setCheckbox] = useState([])

    const [imageUrl1, setImageUrl1] = useState(null);
    const [imageUrl2, setImageUrl2] = useState(null);

    const [inputText, setInputText] = useState('');

    useEffect(() => {
        const getPlateTypes = async () => {
            try {
                const response = await getNumberPlateTypes()
                setPlates(response)
                setFront(response[selectedPlate].front || null)
                setRear(response[selectedPlate].rear || null)
                setCheckbox(response[selectedPlate].PlateExtras.map(item => ({ id: item.id, name: item.name, checked: false })))
            } catch (e) {
                console.error(e)
            }
        }
        getPlateTypes()
    }, []);

    useEffect(() => {
        if (plates.length) {
            getPlateNew()
        }
    }, [plates])

    const getPlateNew = async (text = inputText, id = selectedPlate, extra = checkbox.find(item => item.name === "3D")?.checked) => {
        const plate = plates[id]
        const params = {
            text: text,
            name: plate.name,
            textSize: plate.textSize,
            width: plate.width,
            height: plate.height,
            ...(plate.front) ? { front: true } : null,
            ...(plate.rear) ? { rear: true } : null,
            ...(extra) ? { plate3d: true } : null
        }

        const queryString = new URLSearchParams(params).toString()
        const data = await getPlateImages(queryString);

        setImageUrl1(data[0] ? `data:image/png;base64,${data[0]}` : null);
        setImageUrl2(data[1] ? `data:image/png;base64,${data[1]}` : null);
    }

    const checkboxHandler = (id) => {
        const thingy = checkbox.find(item => item.id === id)
        setCheckbox(prevSelected => prevSelected.map(item => item.id === id ? { ...item, checked: !item.checked } : item));
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            getPlateNew(undefined, undefined, !thingy.checked)
        }, 500)
    }

    const setNewPlate = (id) => {
        setSelectedPlate(id)
        setCheckbox(plates[id].PlateExtras.map(item => ({ id: item.id, name: item.name, checked: false })))
        setFront(plates[id].front || null)
        setRear(plates[id].rear || null)
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            getPlateNew(undefined, id)
        }, 500)
    }

    const handleChange = (event) => {
        setInputText(event.target.value)
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            getPlateNew(event.target.value)
        }, 500)
    }

    const calculatePrice = () => {
        var total = 0;
        checkbox.forEach((item, index) => {
            if (item.checked) {
                const frontTotal = front && Number(plates[selectedPlate]?.PlateExtras[index]?.Price.price)
                const rearTotal = rear && Number(plates[selectedPlate]?.PlateExtras[index]?.Price.price)
                total = total + frontTotal + rearTotal
            }
        })
        if (front && rear) {
            total = total + (Number(plates[selectedPlate]?.Price.price) * 2) - 2
        } else {
            total = total + Number(plates[selectedPlate]?.Price.price)
        }
        return total
    }

    return (
        <div className="home-page">
            <ScrollToTop />
            <div className="hero-section">
                <div className="notification-bubble">
                    This site does not yet support online purchases. Please contact us at <a href="mailto:bav@overdrivetech.co.uk">
                        <span className="email">bav@overdrivetech.co.uk</span>
                    </a> for assistance.
                </div>
                <div className="plate-images">
                    {imageUrl1 && front && <img src={imageUrl1} alt="1" />}
                    {imageUrl2 && rear && <img src={imageUrl2} alt="2" />}
                </div>
                <div className="menu">
                    <div className="select-container">
                        <ToggleButtons options={plates.map(plate => plate.name)} selected={selectedPlate} setSelection={setNewPlate} />
                    </div>
                    <div className="select-container">
                        <SelectButtons front={front} rear={rear} setFront={setFront} setRear={setRear} />
                    </div>
                    <div className="select-container">
                        {plates.length > 0 && plates[selectedPlate].PlateExtras.length > 0 ? plates[selectedPlate].PlateExtras.map((element, index) =>
                            <Checkbox key={index} label={element.name} checked={checkbox.find(extra => extra.id === element.id)?.checked || false} onChange={() => checkboxHandler(element.id)} />
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="user-input">
                <h2 className="price">{`£${calculatePrice()}`}</h2>
                <input type="text" id="registration-number" name="registration-number" value={inputText} placeholder="Enter Your Reg Here" onChange={handleChange} />
            </div>
        </div>
    );
}
