import React, { useContext } from 'react'
import { ThemeContext } from '../../../utils/themeContext'
import './ThemeToggle.css'

export default function ThemeToggle() {
    const { isDarkMode, toggleTheme } = useContext(ThemeContext);

    return (
        <div className="switch">
            <label className="theme-switch" htmlFor="checkbox">
                <input type="checkbox" id="checkbox" checked={isDarkMode} onChange={toggleTheme} />
                <div className="slider round"></div>
            </label>
        </div>

    )
}
