import React from 'react'
import './Button.css'

export default function Button({
  text,
  onClick
}) {
  return (
    <button className="add-to-cart-button" onClick={onClick}>
      <span className="button-text">{text}</span>
    </button>
  )
}
