import React, { useState, useEffect } from 'react';
import './ProductCard.css';
import getProductImage from '../../../utils/getProductImage';
import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';
import { getProductVariant } from '../../../services/ProductVariantService';

function ProductCard({
  product,
  newProduct,
  editProduct,
  deleteProduct,
  isAdmin,
  editable,
  setEditable,
  draft
}) {
  const [quantity, setQuantity] = useState(product.defaultQuantity ?? 1)
  const [editableProduct, setEditableProduct] = useState(product)
  const [price, setPrice] = useState(0)
  const [size, setSize] = useState(0)
  const [colour, setColour] = useState(0)
  const [image, setImage] = useState("")

  const handleChange = (editableProduct) => {
    setEditableProduct(editableProduct)
  }

  useEffect(() => {
    const fetchVariant = async () => {
      try {
        const queryParams = new URLSearchParams()
        queryParams.append('productId', product.id)
        if (product.Sizes.length) {
          queryParams.append('sizeId', product.Sizes[size].id)
        }
        if (product.Colours.length) {
          queryParams.append('colourId', product.Colours[colour].id)
        }
        const url = `?${queryParams.toString()}`
        const response = await getProductVariant(url)
        setPrice(response.price)
        setImage(response.image)
      } catch (e) {
        console.error(e)
      }
    }
    fetchVariant()
  }, [size, colour, product])

  const onDone = (product) => {
    if (draft) {
      newProduct(product)
    } else (
      editProduct(product)
    )
    setEditable(0)
  }

  const handleDropDown = (input, type) => {
    if (type === "size") {
      setSize(input.target.value)
    } else if (type === "colour") {
      setColour(input.target.value)
    }
  }

  const navigate = useNavigate()

  const stringSubstringer = (str) => {
    if (str) {
      return str.length > 80
      ? str.substring(0, 80) + '...'
      : str
    }
    return ""
  }

  return (
    <div className="product-card">
      <div className="clickable-card" >
        {isAdmin ? <Button text={"Delete"} onClick={() => deleteProduct(product.id)} /> : null}
        <div className="card-image" onClick={() => editable !== product.id && !draft ? navigate(`${product.id}`) : null}>
          <img src={getProductImage(image)} alt={product.name} />
        </div>
        <div className="card-content">
          {(editable === product.id) || draft ? <input onChange={(event) => handleChange({ ...editableProduct, name: event.target.value })} type='text' value={editableProduct.name} /> : <h3>{product.name}</h3>}
          {(editable === product.id) || draft ? <input style={{ height: "50px" }} onChange={(event) => handleChange({ ...editableProduct, description: event.target.value })} type='text' value={editableProduct.description} /> : <p className="description">{product.description.length > 80
            ? product.description.substring(0, 80) + '...'
            : product.description}</p>}
          <p className="description">{stringSubstringer(product.Colours[colour]?.description)}</p>
          <p className="description">{stringSubstringer(product.Sizes[size]?.description)}</p>
          {product.Sizes.length > 0 ? (
            <>
              <label htmlFor="size">{`${product.Sizes[size].width ? "Width" : ""} ${product.Sizes[size].width && product.Sizes[size].height ? "x" : ""} ${product.Sizes[size].height ? "Height" : ""} ${product.Sizes[size].height && product.Sizes[size].depth ? "x" : ""} ${product.Sizes[size].depth ? "Depth" : ""}`}:</label>
              {product.Sizes.length > 1 ? (
                <select value={size} onChange={(event) => handleDropDown(event, "size")}>
                  {product.Sizes.map((element, index) => {
                    return <option key={element.id} value={index}>{`${element.width ? element.width + "mm" : ""} ${element.width && element.height ? "x" : ""} ${element.height ? element.height + "mm" : ""} ${element.height && element.depth ? "x" : ""} ${element.depth ? element.depth + "mm" : ""}`}</option>
                  })}
                </select>
              ) : <p className="size">{`${product.Sizes[0].width ? product.Sizes[0].width + "mm" : ""} ${product.Sizes[0].height ? product.Sizes[0].height + "mm" : ""} ${product.Sizes[0].depth ? product.Sizes[0].depth + "mm" : ""}`}</p>}
            </>
          ) : null}
          {product.Colours.length > 0 ? (
            <>
              <label htmlFor="colour">Colour:</label>
              {product.Colours.length > 1 ? (
                <select value={colour} onChange={(event) => handleDropDown(event, "colour")}>
                  {product.Colours.map((element, index) => {
                    return <option key={element.id} value={index}>{element.name}</option>
                  })}
                </select>
              ) : <p className='"colour'>{product.Colours[0].name}</p>}
            </>
          ) : null}
        </div>
      </div>
      <div className="purchase-container">
        {(editable === product.id) || draft ? <><p>£</p><input onChange={(event) => handleChange({ ...editableProduct, Price: { price: event.target.value } })} type='text' value={price} /></> : <p className="price">£{price}</p>}
        {/* {isAdmin ? <EditButton draft={draft} onDone={() => onDone(editableProduct)} edit={editable === product.id || draft} setEdit={() => setEditable(product.id)} /> : <Button text={"ADD TO CART"} onClick={() => { }} />} */}
      </div>
    </div>
  );
}

export default ProductCard;
