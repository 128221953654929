import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import "./Header.css";
import { useLocation, Link } from "react-router-dom";
import ThemeToggle from "../../components/ThemeToggle/ThemeToggle";
import { ThemeContext } from '../../../utils/themeContext'

export default function Header({
    isAdmin,
    setIsAdmin
}) {
    const [isMenu, setIsMenu] = useState(false);
    const menuClass = `menu-btn ${isMenu ? "open" : ""}`;
    const rightSideId = isMenu ? "hidden" : "";
    const location = useLocation()
    const [size, setSize] = useState([0, 0]);
    const { isDarkMode } = useContext(ThemeContext);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
    }, []);
    useEffect(() => {
        if (isMenu) {
            const body = document.body;
            body.style.overflow = 'hidden';
            return () => body.style.overflow = 'initial';
        }
    }, [isMenu])

    useEffect(() => {
        setIsMenu(false);
    }, [size]);

    return (
        <header className={rightSideId}>
            <nav>
                {/* <button className="is-admin-button" onClick={() => { setIsAdmin(!isAdmin) }}>{isAdmin ? "Admin" : "Not Admin"}</button> */}
                <ul className="main_ul">
                    {size[0] <= 1169 &&
                        <li className={menuClass} onClick={() => setIsMenu(!isMenu)}>
                            <div className="menu-btn-burger"></div>
                        </li>
                    }
                    <li className="logo_item">
                        <Link onClick={() => { setIsMenu(false) }} to="/">
                            <img
                                className="logo"
                                src={!isDarkMode ? require("../../../assets/images/Overdrivelogo.png") : require("../../../assets/images/OverdrivelogoWhite.png")}
                                alt="logo"
                            />
                        </Link>
                    </li>
                    <ul className="nav_links" id={rightSideId}>
                        <li {...(location.pathname === "/" && { className: "active_link" })} >
                            <Link onClick={() => { setIsMenu(false) }} to="/" state={{ previousPath: location.pathname }}>
                                Home
                            </Link>
                        </li>
                        <li {...(location.pathname === "/products" && { className: "active_link" })} >
                            <Link onClick={() => { setIsMenu(false) }} to="/products" state={{ previousPath: location.pathname }}>
                                Products
                            </Link>
                        </li>
                        <li>
                            <ThemeToggle />
                        </li>
                    </ul>
                </ul>
            </nav>
        </header>
    );
}
