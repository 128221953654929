import React from 'react';
import "./Checkbox.css"

function ModernCheckbox({ label, checked, onChange }) {
  return (
    <label className="modern-checkbox">
      <div className="checkbox-wrapper-35">
        <input className="switch" type="checkbox" id="switch" name="switch" value="private" checked={checked} onChange={onChange}></input>
        <label htmlFor="switch">
          <span className="switch-x-text">{label} </span>
          <span className="switch-x-toggletext">
            <span className="switch-x-unchecked"><span className="switch-x-hiddenlabel">Unchecked: </span>Off</span>
            <span className="switch-x-checked"><span className="switch-x-hiddenlabel">Checked: </span>On</span>
          </span>
        </label>
      </div>
    </label>
  );
}

export default ModernCheckbox;