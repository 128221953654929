import React from 'react';
import "./ToggleButtons.css"

function ToggleButtons({
  options,
  selected,
  setSelection
}) {
  const handleButtonClick = (index) => {
    setSelection(index)
  };

  return (
    <div className="toggle-buttons">
      {options.map((option, index) => {
        return <button
          key={index}
          className={selected === index ? 'active' : ''}
          onClick={() => handleButtonClick(index)}
        >
          {option}
        </button>
      })}
    </div>
  );
}

export default ToggleButtons;